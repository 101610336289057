<template>
  <div class="field relative" v-show="!hidden" @click="focus">
    <VField
      :name="name"
      v-slot="{ field: { value, ...fieldProps }, meta, errors }"
    >
      <input
        ref="input"
        :id="id"
        :value="value || modelValue || defaultValue"
        v-bind="fieldProps"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('update:modelValue', $event.target.value)"
        class="base-textinput peer placeholder-transparent z-10"
        :class="{
          ' bg-secondary/20': !meta.valid && meta.touched,
          'focus:placeholder-slate-400 px-3 placeholder': placeholder,
        }"
        :placeholder="placeholder ?? label"
        :type="type"
        v-maska
        :data-maska="inputMask"
        :disabled="disabled"
      />
      <EyeIcon
        v-if="isPassword"
        class="w-5 text-secondary absolute md:top-3 top-6 right-2 z-40 cursor-pointer"
        @click="type === 'password' ? (type = 'text') : (type = 'password')"
      />
      <label v-if="label" class="base-textinput-label">
        {{ label }} {{ optional ? `(${$t('common.optional')})` : '' }}
      </label>
      <VErrorMessage
        :name="name"
        as="div"
        class="md:mt-1 mb-2 -mt-3 text-xs text-red-500 px-2"
      />
      <div class="debug" v-if="debug">
        <pre>{{ errors }}</pre>
        <pre>{{ meta }}</pre>
      </div>
    </VField>
  </div>
</template>

<script setup>
import { EyeIcon } from "@heroicons/vue/solid";
import { vMaska } from "maska";

const input = ref(null);
const modelValue = ref(null);
const props = defineProps({
  id: String,
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
    default: "",
  },
  debug: {
    type: Boolean,
    default: false,
  },
  inputClass: {
    type: String,
    default: "",
  },
  inputMask: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  defaultValue: {
    type: String,
    default: undefined,
  },
  optional: {
    type: Boolean,
    default: false
  },
  hidden: {
    type: Boolean,
    default: false
  }
});

function focus() {
  input.value.focus();
}

const isPassword = computed(() => props.type === "password");
</script>

<style scoped>
input:invalid {
  color: #931363;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
